<template>
  <div class="login">
    <div class="login__wrapper">
      <div class="login__logo">
        <img  src="../assets/logo.png" alt="HES Logo">
      </div>
      <div class="login__content">
        <h1>{{ (expired || incomplete) ? 'Ongeldige link':'Nieuw wachtwoord'}}</h1>
        <template v-if="incomplete">
          <p>Neem contact op met de admin om u een geldige wachtwoord-activatie link te versturen.</p>
          <router-link to="/login" class="button">Terug naar login</router-link>
        </template>
        <template v-else-if="expired">
          <p>De huidige link is verlopen of ongeldig, gelieve contact op te nemen met de admin voor een nieuwe wachtwoord-activatie link.</p>
          <router-link to="/login" class="button">Terug naar login</router-link>
        </template>
        <template v-else-if="complete">
          <p>Het nieuwe wachtwoord is succesvol geactiveerd.</p>
          <router-link to="/login" class="button">Login</router-link>
        </template>
        <form v-else action="" class="form login__form" @submit.prevent="resetPassword">
          <div class="form__column">
            <label for="username">Username</label>
            <input type="text" class="input" :value="username" readonly>
            <label for="pass1">Wachtwoord</label>
            <input type="password" autocomplete="new-password" class="input" v-model="user.pass1">
            <label for="password">Bevestig Wachtwoord</label>
            <input type="password" autocomplete="new-password" class="input" v-model="user.pass2">
            <div class="form__wide form__wide--right">
              <button class="button" @click.prevent="resetPassword" :disabled="isLoading">Bevestig</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      user: {
        pass1: '',
        pass2: ''
      },
      isLoading: false,
      complete: false,
      expired: false,
    }
  },

  async created(){
    if(this.incomplete){
      return;
    }
    await this.checkCode();
  },

  computed:{
    code(){
      return this.$route.query.code;
    },
    username(){
      return this.$route.query.username;
    },
    incomplete(){
      return !this.code || !this.username;
    }
  },

  watch:{
    username(newValue,oldValue){
      if(newValue && oldValue && newValue !== oldValue){
        this.checkCode();
      }
    },
    code(newValue,oldValue){
      if(newValue && oldValue && newValue !== oldValue){
        this.checkCode();
      }
    }
  },

  methods:{
    async checkCode(){
      this.isLoading = true;
      try{
        await this.$store.dispatch('user/checkResetCode',{username:this.username,code:this.code});
      }catch(e){
        console.warn(e);
        this.expired = true;
      }
      this.isLoading = false;
    },
    async resetPassword(){
      const {pass1,pass2} = this.user;
      if(!pass1.trim() || !pass2.trim() || pass1 !== pass2 ){
        alert('Gelieve het nieuwe wachtwoord in te voeren en te bevestigen.');
        return;
      }
      this.isLoading = true;
      try{
        await this.$store.dispatch('user/resetPassword',{
          username: this.username,
          password: pass1,
          code: this.code
        });
        this.complete = true;
      }catch(e){
        alert(e);
      }
      this.isLoading = false;
    }
  }
}
</script>